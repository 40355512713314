import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary: React.FC = () => {
    const error = useRouteError();
    return (
        <div className='flex justify-center mt-[10%]'>
            <div>
                <h1 className='text-[4vw] font-bold text-red-600'>Something went wrong.</h1>
                {error instanceof Error ? (
                    <p>{error.message}</p>
                ) : (
                    <p>Unknown error occurred</p>
                )}
            </div>
        </div>
    );
};

export default ErrorBoundary;
