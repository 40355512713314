import React from "react";

const Footer: React.FC = () => {
  return (
    <>
      <div className="footer-div hidden lg:block w-full">
        <div className="footer container mx-auto px-[1rem] lg:px-[1.5rem] py-12">
          <img className="mb-12" src="/stay_home_img/Instacoins Travel Logo.svg" alt="" />
          <div className="flex">
            <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4">
              <ul className="flex flex-col gap-5">
                <li className="font-bold">About</li>
                <li><a href="https://instacoins.travel/about">About us</a></li>
                <li><a href="https://instacoins.travel/how-it-works">How <span className="font-bold">
                  Instacoins.travel</span> works</a></li>
                <li><a href="https://airlines.iata.org/news" target="_blank" rel="noreferrer" >News</a></li>
              </ul>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4">
              <ul className="flex flex-col gap-5">
                <li className="font-bold">Community</li>
                <li><a href="https://www.instagram.com/instacoinsgroup/" target="_blank" rel="noreferrer">Instagram</a></li>
                <li><a href="https://www.facebook.com/p/Instacoins-Group-100082995535845/" target="_blank" rel="noreferrer">Facebook</a></li>
                <li><a href="https://x.com/InstacoinsGroup" target="_blank" rel="noreferrer">X</a></li>
                <li><a href="https://www.tiktok.com/@instacoins.group" target="_blank" rel="noreferrer">Tik Tok</a></li>
                <li><a href="https://www.youtube.com/@Instacoinsgroupofficial" target="_blank" rel="noreferrer">You Tube</a></li>
              </ul>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4">
              <ul className="flex flex-col gap-5">
                <li className="font-bold">Support</li>
                <li><a href="mailto:infor@instacoins.travel">info@instacoins.travel</a></li>
                <li><a href="https://instacoins.travel/terms-conditions">Terms and conditions</a></li>
                <li><a href="https://instacoins.travel/terms-conditions#cancellation-terms">Cancellation Terms</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full h-16 flex bg-[#000F2E]">
          <ul className="footer-down-content flex items-center text-white gap-10 mx-auto container px-[1rem] lg:px-[1.5rem]">
            <li><a href="https://instacoins.travel/terms-conditions">Terms and Conditions</a></li>
            <li><a href="https://instacoins.travel/privacy-policy">Privacy</a></li>
            <li><a href="https://instacoins.travel/sitemap.xml">Sitemap</a></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;


