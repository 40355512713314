import React from 'react'
import parse from 'html-react-parser';

interface PolicyStruct {
  paragraphs: string[];
  title: string;
}
interface MetaPolicyStruct {
  cot: any[];
  extra_bed: any[];
  [key: string]: any;
}

interface HotelInfoData {
  name: string;
  check_in_time: string;
  check_out_time: string;
  metapolicy_extra_info: string;
  policy_struct: PolicyStruct[];
  metapolicy_struct: MetaPolicyStruct;
}

interface HotelHouseRulesProps {
  data: HotelInfoData;
}

const HotelHouseRules: React.FC<HotelHouseRulesProps> = ({ data }) => {
  const checkInTime = data?.check_in_time || "00:00:00";
  const [checkInHour] = checkInTime.split(':').map(Number);
  const checkInProgressWidth = `${(checkInHour / 24) * 100}%`;

  const checkOutTime = data?.check_out_time || "00:00:00";
  const [checkOutHour] = checkOutTime.split(':').map(Number);
  const checkOutProgressWidth = `${(checkOutHour / 24) * 100}%`;

  return (
    <div id="houseRules">
      <div className='mt-8 mx-auto flex justify-center'>
        <div className='w-full lg:w-[60%] flex flex-col gap-8'>
          <div className='w-full lg:w-[60%] flex flex-col gap-8'>
            <p className='text-[20px] font-bold'>House rules</p>
            <p className='text-[#001A4D]'>{data?.name} takes special requests - add in the next step!</p>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-in</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_in_time}</p>
                  <p className='text-end'>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkInProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_in_time} - 00:00</p>
                <div>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-out</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_out_time}</p>
                  <p>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkOutProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_out_time} - 00:00</p>
              </div>
            </div>
          </div>

          <div className='mt-8'>
            <table>
              <tbody className='info-tbody'>
                {/* {data?.policy_struct?.map((policy, policyIndex) => (
                  <React.Fragment key={policyIndex}>
                    <tr>
                      <td className='font-bold pt-4 pl-0 pr-5 align-top'>{policy.title}</td>
                      <td className='pl-2'>
                        {policy?.paragraphs?.map((paragraph, paragraphIndex) => (
                          <div key={paragraphIndex} className='pt-1 p-0 leading-normal'>
                            {parse(paragraph)}
                          </div>
                        ))}
                      </td>
                    </tr>
                  </React.Fragment>
                ))} */}
             
                {
                  data?.metapolicy_extra_info?.length > 0  && parse(data?.metapolicy_extra_info)
                }

                {data?.metapolicy_struct?.cot?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Cot: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Amount: {data?.metapolicy_struct?.cot?.[0].amount}, Currency: {data?.metapolicy_struct?.cot?.[0].currency}, Inclusion: {data?.metapolicy_struct?.cot?.[0].inclusion}, Price: {data?.metapolicy_struct?.cot?.[0].price}, Price unit: {data?.metapolicy_struct?.cot?.[0].price_unit},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.extra_bed?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Extra bed: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Amount: {data?.metapolicy_struct?.extra_bed?.[0].amount}, Currency: {data?.metapolicy_struct?.extra_bed?.[0].currency}, Inclusion: {data?.metapolicy_struct?.extra_bed?.[0].inclusion}, Price: {data?.metapolicy_struct?.extra_bed?.[0].price}, Price unit: {data?.metapolicy_struct?.extra_bed?.[0].price_unit},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.deposite?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Deposite: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Availability: {data?.metapolicy_struct?.deposite?.[0].availability}, Currency: {data?.metapolicy_struct?.deposite?.[0].currency}, Deposit type: {data?.metapolicy_struct?.deposite?.[0].deposit_type}, Payment type: {data?.metapolicy_struct?.deposite?.[0].payment_type}, Price: {data?.metapolicy_struct?.deposite?.[0].price}, Price unit: {data?.metapolicy_struct?.deposite?.[0].price_unit} Pricing method: {data?.metapolicy_struct?.deposite?.[0].pricing_method},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.children_meal?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Children meal: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Age end: {data?.metapolicy_struct?.children_meal?.[0].age_end}, Age start: {data?.metapolicy_struct?.children_meal?.[0].age_start}, Currency: {data?.metapolicy_struct?.children_meal?.[0].currency}, Inclusion: {data?.metapolicy_struct?.children_meal?.[0].inclusion}, Price: {data?.metapolicy_struct?.children_meal?.[0].price}, Meal type: {data?.metapolicy_struct?.children_meal?.[0].meal_type},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.parking?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Parking: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Currency: {data?.metapolicy_struct?.parking?.[0].currency}, Inclusion: {data?.metapolicy_struct?.parking?.[0].inclusion}, Price: {data?.metapolicy_struct?.parking?.[0].price}, Price unit: {data?.metapolicy_struct?.parking?.[0].price_unit}, Territory type: {data?.metapolicy_struct?.parking?.[0].territory_type},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.pets?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Pets: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Currency: {data?.metapolicy_struct?.pets?.[0].currency}, Inclusion: {data?.metapolicy_struct?.pets?.[0].inclusion}, Pets type: {data?.metapolicy_struct?.pets?.[0].pets_type}, Price: {data?.metapolicy_struct?.pets?.[0].price}, Price unit: {data?.metapolicy_struct?.pets?.[0].price_unit},
                      </div>
                    </td>
                  </tr>
                ) : null}

                {data?.metapolicy_struct?.shuttle?.length > 0 ? (
                  <tr>
                    <td className='font-bold pt-4 pl-0 pr-5 align-top'>Shuttle: </td>
                    <td className='pl-2'>
                      <div className='pt-1 p-0 leading-normal'>
                        Currency: {data?.metapolicy_struct?.shuttle?.[0].currency}, Inclusion: {data?.metapolicy_struct?.shuttle?.[0].inclusion}, Destination type: {data?.metapolicy_struct?.shuttle?.[0].destination_type}, Price: {data?.metapolicy_struct?.shuttle?.[0].price}, Shuttle type: {data?.metapolicy_struct?.shuttle?.[0].shuttle_type},
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelHouseRules;