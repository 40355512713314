import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axiosInstance from '../../utils/axiosConfig';
interface Hotel {
  address: string;
  amenity_groups: any[];
  check_in_time: string;
  check_out_time: string;
  description_struct: any[];
  email: string;
  facts: any;
  front_desk_time_end: string;
  front_desk_time_start: string;
  hotel_chain: string;
  id: string;
  images: string[];
  is_closed: boolean;
  is_gender_specification_required: boolean;
  kind: string;
  latitude: number;
  longitude: number;
  metapolicy_extra_info: string;
  metapolicy_struct: any;
  name: string;
  payment_methods: any[];
  phone: string;
  policy_struct: any[];
  postal_code: string;
  region: any;
  room_groups: any[];
  serp_filters: any[];
  star_certificate: string | null;
  star_rating: number;
  [key: string]: any;
}

interface FetchHotelsResponse {
  hotels: Hotel[];
  totalCount: number;
}

interface SearchState {
  data: Hotel[];
  loading: boolean;
  error: string | null;
  isRegionChanged: boolean;
  totalCount: number;
}

const initialState: SearchState = {
  data: [],
  loading: false,
  error: null,
  isRegionChanged: false,
  totalCount: 0,
};

interface FetchSearchDataParams {
  page: number;
  region_id: string;
  checkin_date: string;
  checkout_date: string;
  guest_array: string;
  sort: string | undefined;
}

export const fetchHotelsByRegion = createAsyncThunk<
  FetchHotelsResponse,
  FetchSearchDataParams
>(
  'search/fetchHotelsByRegion',
  async (
    { page, region_id, checkin_date, checkout_date, guest_array, sort },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setIsRegionChanged(true));
    const s_id = Cookies.get('s_id') || '';

    try {
      const response = await axiosInstance.get('/hotelsByRegion', {
        params: {
          page: page,
          region_id: region_id,
          checkin_date: checkin_date,
          checkout_date: checkout_date,
          guest_array: guest_array,
          s_id: s_id,
          sort: sort,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;

      return {
        hotels: data.hotels || [],
        totalCount: data.totalCount || 0,
      };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.error || error?.message);
    }
  },
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearchResults: (state) => {
      state.data = [];
    },
    setIsRegionChanged: (state, action: PayloadAction<boolean>) => {
      state.isRegionChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelsByRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchHotelsByRegion.fulfilled,
        (state, action: PayloadAction<FetchHotelsResponse>) => {
          state.loading = false;
          state.data = action.payload.hotels;
          state.totalCount = action.payload.totalCount;
          state.isRegionChanged = false;
        },
      )
      .addCase(
        fetchHotelsByRegion.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload || 'Failed to fetch search data';
          state.isRegionChanged = false;
        },
      );
  },
});

export const { clearSearchResults, setIsRegionChanged } = searchSlice.actions;
export default searchSlice.reducer;
