import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RoomDetails {
  rooms: number;
  adults: number;
  children: any[];
}

interface SearchState {
  startDate: string | null;
  endDate: string | null;
  roomDetails: RoomDetails[];
  id: string | null;
  hid: number | null;
  name: string | null;
  designation: string | null;
  group: string | null;
  countryCode: string | null;
  residency:string;
  isoCodeAlpha2:string;
}

const initialState: SearchState = {
  startDate: '',
  endDate: '',
  roomDetails: [{ rooms: 1, adults: 2, children: [] }],
  id: '',
  hid: 0,
  name: '',
  designation: '',
  group: '',
  countryCode: '',
  residency:'',
  isoCodeAlpha2:''
};

export const searchVarSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<Partial<SearchState>>) => {
      state.startDate = action.payload.startDate
        ? new Date(action.payload.startDate).toISOString()
        : null;
      state.endDate = action.payload.endDate
        ? new Date(action.payload.endDate).toISOString()
        : null;
      state.roomDetails = action.payload.roomDetails ?? state.roomDetails;
      state.id = action.payload.id ?? state.id;
      state.hid = action.payload.hid ?? state.hid;
      state.name = action.payload.name ?? state.name;
      state.designation = action.payload.designation ?? state.designation;
      state.group = action.payload.group ?? state.group;
      state.countryCode = action.payload.countryCode ?? state.countryCode;
      state.residency = action.payload.residency ?? state.residency;
      state.isoCodeAlpha2 = action.payload.isoCodeAlpha2 ?? state.isoCodeAlpha2;
    },
  },
});

export const { setSearchParams } = searchVarSlice.actions;
export default searchVarSlice.reducer;
