import React from "react";

const Info: React.FC = () => {
    return (
        <>
            <div className="overflow-x-auto w-full">
                <div className="flex space-x-10 py-5 sm:py-5 w-[1000px] md:py-5 lg:py-0 sm:w-[1000px] md:w-[1000px] lg:w-full">
                <div className="info text-[#0C2252] w-full">
                    <h5 className="text-[24px] font-medium">No registration required</h5>
                    <p className="text-[#374151] text-[16px] lg:text-[18px]">
                        We care about your privacy and we offer you the possibility to
                        access any booking service without registration.
                    </p>
                </div>

                <div className="info text-[#0C2252] w-full">
                    <h5 className="text-[24px] font-medium">Low Fares, no Surprises</h5>
                    <p className="text-[#374151] text-[16px] lg:text-[18px]">We strive to offer you the lowest prices on the market.</p>
                </div>

                <div className="info text-[#0C2252] w-full">
                    <h5 className="text-[24px] font-medium">Customer Support</h5>
                    <p className="text-[#374151] text-[16px] lg:text-[18px]">
                        Do you need help or have a question? contact our customer
                        support team now!
                    </p>
                </div>
                </div>
            </div>
            <div className="text-lg text-left mt-3 mb-10 lg:my-10 lg:text-center">
                <p className="text-[#374151] text-[16px] lg:text-[18px]">
                    At Instacoins.travel, you will find one of the largest selections
                    of destinations with over <span className="font-bold">700 airlines</span>, giving access
                    to <span className="font-bold">12 million+ fares</span> for both domestic and
                    international travel. <span className="">We have successfully created a seamless
                    travel booking experience that incorporates blockchain technology,
                    giving customers the ability to{" "}
                    <span className="font-bold">purchase flights using Bitcoin.</span></span>
                </p>
            </div>
        </>
    );
};
export default Info;