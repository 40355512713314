import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosConfig';
interface Rate {
  ccy1: string;
  ccy2: string;
  blockchain2: string;
  rate: number;
  cryptoName: string;
  prec: any;
}

interface SelectedCryptoWithNetwork {
  cryptoName: string;
  blockchain2: string;
}

interface CurrencyState {
  fiatRates: Rate[];
  cryptoRates: Rate[];
  selectedFiat: string;
  selectedCryptoWithNetwork: SelectedCryptoWithNetwork;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CurrencyState = {
  fiatRates: [],
  cryptoRates: [],
  selectedFiat: '',
  selectedCryptoWithNetwork: {
    cryptoName: '',
    blockchain2: '',
  },
  status: 'idle',
  error: null,
};

export const fetchCurrencies = createAsyncThunk<
  Rate[],
  void,
  { rejectValue: string }
>('currency/fetchCurrencies', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<{
      error: string;
      rates: Rate[];
    }>('/currencyRates');

    const data = response.data;

    if (response.status !== 200) {
      throw new Error(data.error || 'Failed to fetch currencies');
    }

    return data.rates;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to fetch currencies');
  }
});

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setSelectedFiat: (state, action: PayloadAction<string>) => {
      state.selectedFiat = action.payload;
    },
    setSelectedCryptoWithNetwork: (
      state,
      action: PayloadAction<SelectedCryptoWithNetwork>,
    ) => {
      state.selectedCryptoWithNetwork = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchCurrencies.fulfilled,
        (state, action: PayloadAction<Rate[]>) => {
          state.status = 'succeeded';

          const fiatRates: Rate[] = [];
          const cryptoRates: Rate[] = [];

          action.payload.forEach((rate) => {
            const cryptoName = rate.ccy2 || 'Unknown';
            const blockchain2 = rate.blockchain2;

            if (blockchain2 === 'FIAT') {
              fiatRates.push(rate);
            } else if (blockchain2 !== 'FIAT' && rate.ccy1 === 'EUR') {
              cryptoRates.push({ ...rate, cryptoName, blockchain2 });
            }
          });

          state.fiatRates = fiatRates;
          state.cryptoRates = cryptoRates;

          if (
            !state.selectedCryptoWithNetwork.cryptoName &&
            cryptoRates.length > 0
          ) {
            state.selectedCryptoWithNetwork = {
              cryptoName: cryptoRates[0].cryptoName,
              blockchain2: cryptoRates[0].blockchain2,
            };
          }

          if (!state.selectedFiat && fiatRates.length > 0) {
            state.selectedFiat = fiatRates[0].ccy1;
          }
        },
      )
      .addCase(
        fetchCurrencies.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = 'failed';
          state.error = action.payload || 'Failed to load data';
        },
      );
  },
});

export const { setSelectedFiat, setSelectedCryptoWithNetwork } =
  currencySlice.actions;

export default currencySlice.reducer;
