import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import useSessionRedirect from '../Hooks/use-session-redirect';
import Layout from '../Layouts/app-layout';
import Home from '../Views/home';
import Search from '../Views/search';
import Hotel from '../Views/hotel';
import ErrorBoundary from '../Components/Shared/error';
import GuestDetails from '../Views/guest-details';
import Payment from '../Views/payment';
import Login from '../Views/Login';

const AppLayout: React.FC = () => {
  useSessionRedirect();
  return <Layout />;
};

const appRouter: React.FC = () => {
  const appRouter = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/hotelsByRegion', element: <Search /> },
        { path: '/hotels', element: <Hotel /> },
        { path: '/hotelDetails/prebook', element: <GuestDetails /> },
      ],
    },
    { path: '/payment', element: <Payment /> },
    { path: '/login', element: <Login /> },
  ],
    {
      // Add future flags to prepare for React Router v7
      future: {
        v7_relativeSplatPath: true,
        // v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
      },
    }
  );

  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  );
};

export default appRouter;
