import { createSlice } from '@reduxjs/toolkit';

export const testSlice = createSlice({
  name: 'test',
  initialState: {
    testInfo: {},
    error: null,
  },
  reducers: {
    setTestInfo: (state, action) => {
      state.testInfo = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setTestInfo, setError } = testSlice.actions;
export default testSlice.reducer;
