import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Components/Shared/navbar';
import Footer from '../Components/Shared/footer';

const Layout: React.FC = () => {
  return (
    <div className="layout">
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;