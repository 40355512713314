import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Typography } from '@mui/material';
import axiosInstance, { setAuthToken } from '../utils/axiosConfig';

const Login = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/auth', { password });
      const { token } = response.data;

      if (token) {
        setAuthToken(token);
        navigate('/');
      } else {
        setErrorMessage('Authentication failed. No token received.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Authentication failed. Please check your credentials.');
    }
  };

  return (
    <div className='bg-[#909090] w-full h-[100vh]'>
    <Container
      component='main'
      maxWidth='xs'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10vh',
      }}
    >
      <div className='text-center flex flex-col w-full bg-slate-200 rounded-lg p-4 shadow-2xl'>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form onSubmit={handleLogin} style={{ width: '100%', marginTop: 1 }}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type='submit'
            className='bg-[#1d4699] w-full p-2 text-[#FFFFFF] rounded-md mt-2 hover:bg-[#002E8A]'
          >
            Login
          </button>
          {errorMessage && (
            <Typography color='error' style={{ marginTop: '20px' }}>
              {errorMessage}
            </Typography>
          )}
        </form>
      </div>
    </Container>
    </div>
  );
};

export default Login;
