import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCurrencies,
  setSelectedFiat,
  setSelectedCryptoWithNetwork,
} from '../../Redux/slices/currency-slices';
import { RootState, AppDispatch } from '../../Redux/store';
import { Box, Modal, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { XMarkIcon } from '@heroicons/react/24/outline';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Navbar: React.FC = () => {
  const [openTripModal, setOpenTripModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleOpenTrip = () => setOpenTripModal(true);

  const handleCloseTrip = () => {
    setOpenTripModal(false);
    setOrderId('');
    setPhoneNumber('');
  };

  const handleSubmit = () => {
    const sanitizedPhoneNumber = phoneNumber.replace(/^\+/, '');

    navigate(
      `/payment?orderId=${orderId}&phone_number=${sanitizedPhoneNumber}`,
    );
    handleCloseTrip();
  };

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const {
    selectedFiat,
    selectedCryptoWithNetwork,
    cryptoRates,
    fiatRates,
    status,
  } = useSelector((state: RootState) => state.currency);

  const isHomePage = location.pathname === '/';
  const isPaymentPage = location.pathname === `/payment`;

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCurrencies());
    }
  }, [dispatch, status]);

  const fiatSelections =
    fiatRates.length > 0
      ? [fiatRates[0].ccy1, ...fiatRates.map((item: any) => item.ccy2)]
      : [];

  const handleFiatChange = (event: any) => {
    dispatch(setSelectedFiat(event.target.value));
  };

  const handleCryptoChange = (event: any) => {
    const [cryptoName, blockchain2] = event.target.value.split('/');
    dispatch(setSelectedCryptoWithNetwork({ cryptoName, blockchain2 }));
  };

  const cryptoValue = selectedCryptoWithNetwork
    ? `${selectedCryptoWithNetwork.cryptoName}/${selectedCryptoWithNetwork.blockchain2}`
    : '';

  return (
    <Disclosure as='nav' className='bg-white sticky top-0 z-10'>
      <div className='mx-auto px-[1rem] lg:px-[1.5rem] container'>
        <div className='relative flex h-16 items-center justify-between'>
          <div className='absolute inset-y-0 right-0 flex items-center lg:hidden'>
            <DisclosureButton className='group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
              <span className='absolute -inset-0.5' />
              <span className='sr-only'>Open main menu</span>

              <XMarkIcon
                aria-hidden='true'
                className='hidden h-6 w-6 group-data-[open]:block'
              />
              <div className='block h-6 w-6 group-data-[open]:hidden'>
                <img src='/stay_home_img/MenuFold.png' alt='' />
              </div>
            </DisclosureButton>
          </div>
          <div className='flex items-center justify-between sm:items-stretch sm:justify-start'>
            <NavLink to='/' className='flex flex-shrink-0 items-center'>
              <img
                alt='Your Company'
                src='/stay_home_img/Instacoins Travel Logo.svg'
                className='h-8 w-auto'
              />
            </NavLink>
            <div className='hidden sm:ml-6 lg:block'></div>
          </div>
          <div className='hidden sm:ml-6 lg:block'>
            <div className='flex gap-4 items-center'>
              <div className='button-div'>
                <button
                  onClick={handleOpenTrip}
                  className='text-[#1F2A37] w-[100px] rounded-md border border-[#9CA3AF] px-3 py-2 hover:text-gray-800'
                >
                  My stay
                </button>

                <Modal
                  open={openTripModal}
                  onClose={handleCloseTrip}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      padding: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    className='w-[90%] lg:w-auto'
                  >
                    <div className='flex flex-col gap-5 p-2 lg:p-5'>
                      <div className='flex items-center justify-between'>
                        <p className='font-bold text-[18px]'>My stay</p>
                        <div className='border border-gray-300 rounded-lg w-[35px] h-[35px] flex justify-center'>
                          <IconButton
                            onClick={handleCloseTrip}
                            aria-label='close'
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>

                      <div>
                        <p>
                          Please provide the order number and the phone number
                          used for your reservation.
                        </p>
                      </div>
                      <div className='flex flex-col gap-3'>
                        <p>Order number*</p>
                        <TextField
                          id='orderId'
                          label='Order ID'
                          type='text'
                          fullWidth
                          size='small'
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                        />
                      </div>

                      <div className='flex flex-col gap-3'>
                        <p>Phone number*</p>

                        <div style={{ width: '100%' }}>
                          <PhoneInput
                            international
                            value={phoneNumber}
                            onChange={(value: string | undefined) => {
                              if (value !== undefined) {
                                setPhoneNumber(value);
                              } else {
                                setPhoneNumber('');
                              }
                            }}
                            style={{
                              width: '100%',
                              height: '40px',
                              border: '1px solid #c4c4c4',
                              borderRadius: '4px',
                              padding: '10px',
                              fontSize: '16px',
                            }}
                          />
                        </div>
                      </div>

                      <div className='w-full'>
                        <button
                          onClick={handleSubmit}
                          disabled={!orderId || !phoneNumber}
                          className={`w-full bg-[#002E8A] text-[#FFFFFF] rounded-lg flex items-center justify-center border py-3 ${!orderId || !phoneNumber
                              ? 'opacity-50 cursor-not-allowed'
                              : 'opacity-100'
                            }`}
                        >
                          Find my trip
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>

              {!isHomePage && !isPaymentPage && (
                <div className='drop-down-area flex items-center'>
                  <FormControl sx={{}} size='small'>
                    <Select
                      value={
                        selectedFiat ||
                        (fiatRates.length > 0 ? fiatRates[0].ccy1 : '')
                      }
                      onChange={handleFiatChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ width: 100, marginRight: 2 }}
                    >
                      {fiatSelections.map((fiat, index) => (
                        <MenuItem key={index} value={fiat}>
                          {fiat}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{}} size='small'>
                    <Select
                      value={cryptoValue || ''}
                      onChange={handleCryptoChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ width: 210 }}
                    >
                      {cryptoRates.map((rate: any) => (
                        <MenuItem
                          key={`${rate.cryptoName}/${rate.blockchain2}`}
                          value={`${rate.cryptoName}/${rate.blockchain2}`}
                        >
                          {`${rate.cryptoName}/${rate.blockchain2}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className='block lg:hidden'>
        <div className='space-y-1 px-[1rem] pb-3 pt-2'>
          <div className='flex gap-3 flex-col lg:flex-row'>
              <div className='button-div order-2 lg:order-1'>
                <button
                  onClick={handleOpenTrip}
                  className='text-black rounded-lg border px-2 py-1 w-full hover:text-gray-800'
                >
                  My stay
                </button>
              </div>

            {!isHomePage && !isPaymentPage && (
              <div className='drop-down-area flex items-center gap-2'>
                <FormControl fullWidth size='small'>
                  <Select
                    value={
                      selectedFiat ||
                      (fiatRates.length > 0 ? fiatRates[0].ccy1 : '')
                    }
                    onChange={handleFiatChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {fiatSelections.map((fiat, index) => (
                      <MenuItem key={index} value={fiat}>
                        {fiat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size='small'>
                  <Select
                    value={cryptoValue || ''}
                    onChange={handleCryptoChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {cryptoRates.map((rate: any) => (
                      <MenuItem
                        key={`${rate.cryptoName}/${rate.blockchain2}`}
                        value={`${rate.cryptoName}/${rate.blockchain2}`}
                      >
                        {`${rate.cryptoName}/${rate.blockchain2}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};
export default Navbar;
