import React, { useState } from "react";

const NextDestination: React.FC = () => {
  const [selectedRegion, setSelectedRegion] = useState<string>('Worldwide');

  const destinations = [
    { name: 'Paris', country: 'France', region: 'Europe', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F0b3129ea-65b9-4f0f-abc7-dc300f434897&w=3840&q=75' },
    { name: 'New York', country: 'United States of America', region: 'North America', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Febdc95d8-105c-4d87-8200-eaf40a951a0e&w=3840&q=75' },
    { name: 'Madrid', country: 'Spain', region: 'Europe', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fe3b514ee-d186-4d2d-b803-232fb918be9c&w=3840&q=75' },
    { name: 'Sydney', country: 'Australia', region: 'Pacific Ocean and Australia', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F9d2a9ded-0eb5-4ad0-a2dd-e88e935c227b&w=3840&q=75' },
    { name: 'Los Angeles', country: 'United States of America', region: 'North America', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F76436dd4-8d7a-42fd-91aa-ad900e0095b2&w=3840&q=75' },
    { name: 'Abu Dhabi', country: 'United Arab Emirates', region: 'Middle East', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fcc64ae9a-30f0-4f4f-8012-b844c3d61ef5&w=3840&q=75' },
  ];

  const filteredDestinations = selectedRegion === 'Worldwide'
    ? destinations
    : destinations.filter(destination => destination.region === selectedRegion);

  return (
    <>
      <div className="next-destination-div ">
        <div className="div-heading mb-5">
          <h5 className="heading">Pick your next destination</h5>
          <p className="sub-heading">
            Search Flights to our most popular destinations
          </p>
        </div>

        <div className="overflow-x-auto hides-scrollbar">
          <div className="flex items-center gap-4 py-2 w-[1450px] justify-center sm:flex-wrap sm:w-full lg:justify-cente lg:flex-wrap lg:w-full">
            <button onClick={() => setSelectedRegion('Worldwide')} className={`destination-btn ${selectedRegion === 'Worldwide' ? 'selected' : ''}`}>
              <p>Worldwide</p>
            </button>
            <button onClick={() => setSelectedRegion('Europe')} className={`destination-btn ${selectedRegion === 'Europe' ? 'selected' : ''}`}>
              <p>Europe</p>
            </button>
            <button onClick={() => setSelectedRegion('North America')} className={`destination-btn ${selectedRegion === 'North America' ? 'selected' : ''}`}>
              <p>North America</p>
            </button>
            <button onClick={() => setSelectedRegion('Asia')} className={`destination-btn ${selectedRegion === 'Asia' ? 'selected' : ''}`}>
              <p>Asia</p>
            </button>
            <button onClick={() => setSelectedRegion('Middle East')} className={`destination-btn ${selectedRegion === 'Middle East' ? 'selected' : ''}`}>
              <p>Middle East</p>
            </button>
            <button onClick={() => setSelectedRegion('Pacific Ocean and Australia')} className={`destination-btn ${selectedRegion === 'Pacific Ocean and Australia' ? 'selected' : ''}`}>
              <p>Pacific Ocean and Australia</p>
            </button>
            <button onClick={() => setSelectedRegion('South America')} className={`destination-btn ${selectedRegion === 'South America' ? 'selected' : ''}`}>
              <p>South America</p>
            </button>
            <button onClick={() => setSelectedRegion('Africa')} className={`destination-btn ${selectedRegion === 'Africa' ? 'selected' : ''}`}>
              <p>Africa</p>
            </button>
            <button onClick={() => setSelectedRegion('Central America and Caribbean')} className={`destination-btn ${selectedRegion === 'Central America and Caribbean' ? 'selected' : ''}`}>
              <p>Central America and Caribbean</p>
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="destination-img-div w-[1200px] grid grid-cols-4 gap-2 mt-5 relative md:w-[1200px] sm:w-[1200px] lg:w-full lg:mt-7">
            {filteredDestinations.map((destination, index) => (
              <div key={index} className={`img-div col-span-1 relative h-64 
                  ${index === 0 ? 'col-span-2' : index === 5 ? 'col-span-2' : index === 1 || 2 || 3 || 4 ? 'col-sapn-1' : null}`}>
                <img
                  className="object-cover w-full h-full rounded"
                  src={destination.image}
                  alt={destination.name}
                />
                <div className="country-img-div flex items-center justify-between absolute inset-x-0 p-4 bottom-0 text-white">
                  <div className="country-img-name">
                    <h4 className="place">{destination.name}</h4>
                    <p className="captal">{destination.country}</p>
                  </div>
                  <div className="country-img-detail">
                    <p className="start-from">Starting from</p>
                    <h4 className="amount">$ 250,00</h4>
                    <p className="longitude">Est. 0.00239 BTC</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default NextDestination;