import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosInstance, { setAuthToken } from '../utils/axiosConfig';
import { AxiosResponse } from 'axios';

interface SessionResponse {
  sessionId: string;
}

const useSessionRedirect = (): void => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    const excludePaths = ['/payment', '/login'];

    const isExcludedPath = excludePaths.some((path) =>
      currentPath.startsWith(path),
    );

    const updateURLWithSessionId = (sessionId: string): void => {
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has('s_id')) {
        searchParams.set('s_id', sessionId);
        navigate(`${currentPath}?${searchParams.toString()}`, {
          replace: true,
        });
      }
    };

    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login');
    } else {
      setAuthToken(authToken);

      if (!isExcludedPath) {
        let sessionId = Cookies.get('s_id');
        if (!sessionId) {
          axiosInstance
            .get<SessionResponse>('/sessionId')
            .then((response: AxiosResponse<SessionResponse>) => {
              sessionId = response.data.sessionId;
              Cookies.set('s_id', sessionId, { expires: 1 });
              updateURLWithSessionId(sessionId);
            })
            .catch((error) => {
              console.error('Failed to fetch session ID:', error);
            });
        } else {
          updateURLWithSessionId(sessionId);
        }
      }
    }
  }, [navigate, location]);
};

export default useSessionRedirect;
