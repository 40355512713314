import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store';
import PriceSummarySkeleton from '../../Skeleton/price-summary-skeleton';
interface PriceSummaryProps {
  prebookStatus: string;
  prebookData: any;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({
  prebookStatus, prebookData
}) => {
  const rates = useSelector((state: RootState) => state.currency.cryptoRates);
  const fiatRates = useSelector((state: RootState) => state.currency.fiatRates);
  const selectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork,
  );
  const cryptoRateItem = rates.find(
    (item: any) =>
      item.ccy2 === selectedCrypto.cryptoName &&
      item.blockchain2 === selectedCrypto.blockchain2,
  );
  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 'Rate not found';
  const selectedFiat = useSelector(
    (state: RootState) => state.currency.selectedFiat,
  );
  let selectedFiatArray = fiatRates.find(
    (item: any) => item.ccy2 === selectedFiat,
  );
  let PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate;
  let precFiatVal = selectedFiatArray ? Number(selectedFiatArray.prec) : 2;
  const formatPrice = (price: any, conversionRate: any) => {
    return Number(price) / Number(conversionRate);
  };

  const results = useSelector((state: RootState) => state.room.roomInfo);
  const roomPriceAmount = prebookData?.prebook_price || 0;
  const roomCryptoPrice = results
    ? Number(
      formatPrice(roomPriceAmount, cryptoRate).toFixed(
        Number(cryptoRateItem?.prec),
      ),
    )
    : Number(0);
  return (
    <>
      {prebookStatus === 'success' ? (
        <>
          {prebookData.pay_on_arrival && Array.isArray(prebookData.pay_on_arrival) ? (
            <>
              <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2'>
                <p className="font-bold">Pay on arrival</p>
                {prebookData.pay_on_arrival.map((item: any, index: any) => (
                  <div key={index} className="flex justify-between items-start gap-2">
                    <span className="">
                      {item.name
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char: string) => char.toUpperCase())}
                    </span>
                    <div>
                      {/* <span className="">{PriceRate && (item.amount * PriceRate).toFixed(precFiatVal,)}</span><span> {selectedFiat}</span> */}
                      <span className="">{ item.amount }</span><span> {item.currency_code}</span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            null
          )}

          <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2'>
            <p className='font-bold'>Your price summary</p>
            <div className='flex items-start justify-between gap-2'>
              <p className='w-[65%]'>{prebookData.room_type}</p>
              <div className='flex justify-end w-[35%]'>
                <p>
                  {PriceRate &&
                    (prebookData.prebook_price * PriceRate).toFixed(precFiatVal,)}
                  <span> {selectedFiat}</span>
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between'>
              <p>Total</p>
              <p>
                {PriceRate &&
                  (prebookData.prebook_price * PriceRate).toFixed(precFiatVal)}
                <span> {selectedFiat}</span>
              </p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <p>Est. price in</p>
                {/* <img src="/stay_guest_details_img/coin.svg" alt="" /> */}
              </div>
              <p>
                {roomCryptoPrice || 0} {selectedCrypto.cryptoName}
              </p>
            </div>
          </div>
        </>
      ) : (
        <PriceSummarySkeleton />
      )}
    </>
  );
};
export default PriceSummary;
