import React from 'react'
import { Skeleton } from '@mui/material';

const HotelDetailsCardSkeleton: React.FC = () => {
    return (
        <div>
            <div className='hidden lg:block mb-5'>
                <div className="flex gap-6 items-center w-full">
                    <Skeleton
                        variant="rectangular"
                        height={230}
                        className="w-[45%] rounded-xl"
                    />
                    <div className="w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <Skeleton width={100} />
                            <Skeleton height={40} width={450} />
                            <div className='flex items-center gap-5 w-full'>
                                <Skeleton
                                    variant="rectangular"
                                    height={40}
                                    width={40}
                                    className="rounded-xl"
                                />
                                <Skeleton width={50} />
                            </div>
                            <div className='w-full'>
                                <Skeleton width={300} />
                                <Skeleton width={100} />
                            </div>
                            <div className='w-full flex gap-4'>
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                                <Skeleton height={50} width={40} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='block lg:hidden'>
                <div className='mt-5'>
                    <div className='flex w-full gap-4 items-center'>
                        <Skeleton
                            variant="rectangular"
                            height={100}
                            width={200}
                            className="w-[100%] rounded-xl"
                        />
                        <div className='w-full flex flex-col gap-1'>
                            <Skeleton />
                            <Skeleton width={100} />
                        </div>
                    </div>
                    <div className='w-full flex gap-10 mt-2'>
                        <div className='w-full'>
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <div className='w-full'>
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                    <div className='w-full mt-3 flex'>
                        <div className='w-full flex gap-4'>
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                            <Skeleton height={50} width={40} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HotelDetailsCardSkeleton;