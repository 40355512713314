import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface CryptoState {
  addressData: {
    orderId: string;
    phone_number: string;
  } | null;
  loading: boolean;
  error: string | null;
}

const initialState: CryptoState = {
  addressData: null,
  loading: false,
  error: null,
};

interface FetchGenerateCryptoParams {
  s_id: string;
}

export const generateCryptoAddress = createAsyncThunk<
  any,
  FetchGenerateCryptoParams,
  { state: RootState; rejectValue: string }
>('crypto/generateAddress', async ({ s_id }, { getState, rejectWithValue }) => {
  try {
    const { userDetails } = getState().user;
    const { selectedCryptoWithNetwork, selectedFiat } = getState().currency;
    const { data: hotelInfo } = getState().hotelInfo;
    // const { data: prebookInfo } = getState().prebook;
    const bookingInfo = getState().search;
    const { roomInfo } = getState().room;

    const body = JSON.stringify({
      userDetails,
      selectedFiat,
      selectedCryptoWithNetwork,
      hotelInfo: hotelInfo.id,
      prebookInfo: '',
      bookingInfo,
      roomInfo,
    });

    const response = await fetch(
      `https://euuql6gjy3.execute-api.eu-north-1.amazonaws.com/dev/booking?s_id=${s_id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const generateCryptoAddressSlice = createSlice({
  name: 'crypto',
  initialState,
  reducers: {
    resetCryptoState: (state) => {
      state.addressData = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateCryptoAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        generateCryptoAddress.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log('Fulfilled payload:', action.payload);
          state.loading = false;
          state.addressData = {
            orderId: action.payload.orderId,
            phone_number: action.payload.phone_number,
          };
        },
      )
      .addCase(
        generateCryptoAddress.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.loading = false;
          state.error = action.payload || 'Unknown error';
        },
      );
  },
});

export const { resetCryptoState } = generateCryptoAddressSlice.actions;
export default generateCryptoAddressSlice.reducer;
