import React, { useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PopupProps {
    order: any;
    timeLeft: any;
    onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ order, timeLeft, onClose }) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const autoCloseTimer = setTimeout(() => {
            onClose();
        }, 10000);

        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            clearTimeout(autoCloseTimer);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="absolute top-20 lg:top-32 left-0 w-full h-full flex items-start justify-center mx-auto z-50">
            <div ref={popupRef} className="bg-[#FFF8F1] p-5 rounded-md shadow-lg relative items-start flex justify-between gap-3">
                <div className=''>
                    {order?.orderStatus === "waiting_payment" ? (
                        timeLeft === "00:00:00" ? (
                            <div className='flex flex-col gap-2'>
                                <div className='flex items-center gap-2'>
                                    <img className="w-[15px] h-[15px]" src="/stay_payment_img/check-circle.svg" alt="" />
                                    <p className='font-semibold text-[#C62828]'>Payment expired</p>
                                </div>
                                <p className='text-[14px] text-[#C62828]'>The booking expired waiting for your payment. Please search again.</p>
                            </div>
                        ) :
                            <div className='flex flex-col gap-2'>
                                <div className='flex items-center gap-2'>
                                    <img className="w-[15px] h-[15px]" src="/stay_payment_img/clock.svg" alt="" />
                                    <p className='font-semibold text-[#8A2C0D]'>Waiting for your payment</p>
                                </div>
                                <p className='text-[14px] text-[#8A2C0D]'>To complete your purchase, please send the indicated amount to the wallet address: <span className='break-all'>{order?.wallet_address}</span></p>
                            </div>

                    ) : order?.orderStatus === "partial_payment" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/clock.svg" alt="" />
                                <p className='font-semibold text-[#8A2C0D]'>Partial payment</p>
                            </div>
                            <p className='text-[14px] text-[#8A2C0D]'>Partial payment received, waiting for complete payment.</p>
                        </div>
                    ) : order?.orderStatus === "payment_received" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/check-circle.svg" alt="" />
                                <p className='font-semibold text-[#03543F]'>Payment received</p>
                            </div>
                        </div>
                    ) : order?.orderStatus === "processing_booking" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/clock.svg" alt="" />
                                <p className='font-semibold text-[#8A2C0D]'>Processing booking</p>
                            </div>
                            <p className='text-[14px] text-[#8A2C0D]'>We are completing your booking!</p>
                        </div>
                    ) : order?.orderStatus === "booking_completed" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/check-circle.svg" alt="" />
                                <p className='font-semibold text-[#03543F]'>Booking completed</p>
                            </div>
                            <div className='leading-6'>
                                <p className='text-[14px] text-[#03543F]'>Your hotel has been booked successfully! Your order number is {order.order_number}.</p>
                                <p className='text-[14px] text-[#03543F]'>We sent you a copy of this confirmation to your email {order?.user_email}</p>
                            </div>
                        </div>
                    ) : order?.orderStatus === "payment_expired" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/check-circle.svg" alt="" />
                                <p className='font-semibold text-[#C62828]'>Payment expired</p>
                            </div>
                            <p className='text-[14px] text-[#C62828]'>The booking expired waiting for your payment. Please search again.</p>
                        </div>
                    ) : order?.orderStatus === "booking_error" ? (
                        <div className='flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <img className="w-[15px] h-[15px]" src="/stay_payment_img/check-circle.svg" alt="" />
                                <p className='font-semibold text-[#C62828]'>Booking error</p>
                            </div>
                            <p className='text-[14px] text-[#C62828]'>The hotel booking error, please book again.</p>
                        </div>
                    ) : null}
                </div>
                <div className='w-[24px] h-[24px] flex justify-center'>
                    <IconButton
                        onClick={onClose}
                        aria-label='close'
                    >
                        <CloseIcon className='text-[#8A2C0D]' />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
export default Popup;
