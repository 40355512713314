import React from 'react';

const ConfirmationNumber: React.FC<any> = ({ orderDetails, timeLeft }) => {
  return (
    <>
      <div className='mt-8  flex flex-col gap-3'>
        <p className='text-[24px] font-bold'>
          Thank you, {orderDetails.user_title} {orderDetails.user_firstName}!
        </p>
        <p className='text-[18px] text-[#374151]'>
          Order number:{' '}
          <span className='text-[#000000] font-bold'>
            {orderDetails.order_number}
          </span>
        </p>

        <p className='text-[18px] text-[#374151]'>
          Booking Status:{' '}
          <span className='text-[#36b80f] font-bold'>
            {orderDetails?.orderStatus === 'waiting_payment'
              ? (
                timeLeft === "00:00:00" ? 'The booking expired waiting for your payment. Please search again.' : 'Waiting for your payment'
              )
              : orderDetails?.orderStatus === 'partial_payment'
                ? (
                  timeLeft === "00:00:00" ? 'The payment expired, please search again.' : 'Partial payment received, waiting for complete payment.'
                )
                : orderDetails?.orderStatus === 'payment_received'
                  ? 'Payment received!'
                  : orderDetails?.orderStatus === 'processing_booking'
                    ? 'We are completing your booking!'
                    : orderDetails?.orderStatus === 'booking_completed'
                      ? 'Booking completed!'
                      : orderDetails?.orderStatus === 'payment_expired'
                        ? 'The payment expired, please search again.'
                        : orderDetails?.orderStatus === 'booking_error'
                          ? 'Booking error' : ''}
          </span>
        </p>
        {orderDetails &&
          orderDetails.orderStatus === 'partial_payment' &&
          orderDetails.partial_details ? (
          <div className='text-[18px] mb-5'>
            <p className='text-[#000000] font-medium'>
              Your total payment is:{' '}
              {Object.values(orderDetails.partial_details).reduce<number>(
                (total, amount) => total + parseFloat(amount as string),
                0,
              )}{' '}
              {orderDetails.crypto_type}
            </p>
            <br></br>
            <p>
              You need to pay total: {orderDetails.crypto_price}{' '}
              {orderDetails.crypto_type} on network{' '}
              {orderDetails.crypto_network}
            </p>
          </div>
        ) : null}
        <div className='text-[18px] mb-5'>
          <div className='text-[#000000] font-medium flex flex-col'>
            <span >
              Thank you for booking your hotel with <b>Instacoins!</b> Below is
              a summary of your booking to <b>{orderDetails.hotel_name}</b>.
            </span>

            <span >
              We’ve sent a copy of your booking information to your email
              address: <b>{orderDetails.user_email}</b>
            </span>
            <p>You can also find this page again in <span className='text-[#0036A4] border-b-2'>My stay</span>.</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmationNumber;
